import { initPlasmicLoader } from '@plasmicapp/loader-nextjs';
import { EmailConfirmationBanner } from 'components/confirmation/EmailConfirmationBanner';
import { EntryMethod } from 'components/enter/EntryMethod';
import TrendingThisMonthSection from 'components/landing/StrongerVariant/TrendingThisMonthSection/TrendingThisMonthSection';
import { EmptyText } from 'components/layout/EmptyText';
import { PlasmicEnterCheckout } from 'components/payments/PlasmicEnterCheckout';
import { CardSlider } from 'components/plasmic/CardSlider';
import { CarouselSlider } from 'components/plasmic/CarouselSlider';
import { ActivityFeedGlobalContext } from 'context/ActivityFeedGlobalContext';
import { AuthenticationGlobalContext } from 'context/AuthenticationGlobalContext';
import { ConfirmationGlobalContext } from 'context/ConfirmationGlobalContext';
import { DrawGlobalContext } from 'context/DrawGlobalContext';
import { EnterFlowGlobalContext } from 'context/EnterGlobalContext';
import { PrizeGlobalContext } from 'context/PrizeGlobalContext';
import { RootGlobalContext } from 'context/RootGlobalContext';
import { ScrollGlobalContext } from 'context/ScrollGlobalContext';
import { UIGlobalContext } from 'context/UIGlobalContext';
import { UserGlobalContext } from 'context/UserGlobalContext';
import { WinnerGlobalContext } from 'context/WinnerGlobalContext.';
import { validMultistepTestGroups } from 'utils/ab-test/multistep/MultistepV4';
import { runningInProduction } from 'utils/constants';

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: 'jmAtDALSpsSy16iTzMBxFU', // ID of a project you are using
      token:
        'AgzyWhFl9v3EGxcDjXciQJ2OQgH5PRBEhjOrBvuDowN8lpxUt2zdHBdtAQ0XyErS09dyQwEniza6IlszL38kw', // API token for that project
      version: undefined,
    },
  ],
  // Fetches the latest revisions, whether or not they were unpublished!
  // Disable for production to ensure you render only published changes.
  preview: !runningInProduction,
});

PLASMIC.registerTrait('chooseYourPlanVariant', {
  type: 'text',
  label: 'choosePlanVariant',
});

PLASMIC.registerTrait('MultistepV4Variant', {
  type: 'choice',
  label: 'MultistepV4Variant',
  options: validMultistepTestGroups,
});

PLASMIC.registerTrait('HomepageTestVariant', {
  type: 'choice',
  label: 'HomepageTestVariant',
  options: ['CONTROL', 'VARIANT3', 'VARIANT3CTA'],
});

PLASMIC.registerTrait('winnerSectionVariant', {
  type: 'text',
  label: 'winnerSectionVariant',
});

PLASMIC.registerGlobalContext(RootGlobalContext, {
  name: 'RootGlobalContext',
  props: {},
  providesData: true,
  globalActions: {
    RouterPush: {
      parameters: [{ name: 'url', type: 'string' }],
    },
  },
});

PLASMIC.registerGlobalContext(DrawGlobalContext, {
  name: 'DrawGlobalContext',
  props: {},
  providesData: true,
});

PLASMIC.registerGlobalContext(UIGlobalContext, {
  name: 'UIGlobalContext',
  props: {},
  providesData: true,
  globalActions: {
    setShowVideoOverlay: {
      parameters: [{ name: 'show', type: 'boolean' }],
    },
    setVideoOverlayLink: {
      parameters: [{ name: 'link', type: 'string' }],
    },
    setVideoOverlayType: {
      parameters: [{name: 'type', type: 'string'}]
    },
    removeVideoOverlayLink: { parameters: [] },
  },
});

PLASMIC.registerGlobalContext(AuthenticationGlobalContext, {
  name: 'AuthFlowGlobalContext',
  props: {},
  providesData: true,
  globalActions: {
    updateEmail: { parameters: [{ name: 'email', type: 'string' }] },
    updatePassword: { parameters: [{ name: 'password', type: 'string' }] },
    setFirstName: { parameters: [{ name: 'first_name', type: 'string' }] },
    setLastName: { parameters: [{ name: 'last_name', type: 'string' }] },
    setAgreedToMarketing: {
      parameters: [{ name: 'value', type: 'boolean' }],
    },
    setAgreedToTerms: { parameters: [{ name: 'value', type: 'boolean' }] },
    submitEmailLogin: { parameters: [] },
    submitRegistration: { parameters: [] },
    doGoogleSignIn: { parameters: [] },
    doGoogleRegister: { parameters: [] },
    doAppleSignIn: { parameters: [] },
    doAppleRegister: { parameters: [] },
    doFacebookSignIn: { parameters: [] },
    doFacebookRegister: { parameters: [] },
  },
});

PLASMIC.registerGlobalContext(EnterFlowGlobalContext, {
  name: 'EnterFlowGlobalContext',
  props: {},
  providesData: true,
  globalActions: {
    setEntryMethod: {
      parameters: [
        {
          name: 'entryMethod',
          type: {
            type: 'choice',
            options: Object.keys(EntryMethod),
            defaultValueHint: EntryMethod.PremiumSubscription,
          },
        },
      ],
    },
    setNumEntries: { parameters: [{ name: 'num_entries', type: 'number' }] },
    setLines: { parameters: [{ name: 'lines', type: 'object' }] },
    removeLine: { parameters: [{ name: 'line_number', type: 'number' }] },
    setLineNumber: {
      parameters: [
        { name: 'line_number', type: 'number' },
        { name: 'position', type: 'number' },
        { name: 'value', type: 'number' },
      ],
    },
    toggleLineNumber: {
      parameters: [
        { name: 'line_number', type: 'number' },
        { name: 'value', type: 'number' },
      ],
    },
    toggleLineBonusNumber: {
      parameters: [
        { name: 'line_number', type: 'number' },
        { name: 'value', type: 'number' },
      ],
    },
    generateLineRandomNumbers: {
      parameters: [{ name: 'line_number', type: 'number' }],
    },
    navigateToEnterTest: {
      parameters: [{ name: 'link', type: 'string' }],
    },
    navigateToCheckout: {
      parameters: [],
    },
    setDays: {
      parameters: [
        {
          name: 'days',
          type: {
            type: 'choice',
            options: ['FRIDAY', 'TUESDAY', 'BOTH'],
            defaultValueHint: 'TUESDAY',
          },
        },
      ],
    },
    toggleDay: {
      parameters: [
        {
          name: 'day',
          type: {
            type: 'choice',
            options: ['FRIDAY', 'TUESDAY'],
          },
        },
      ],
    },
    setBoosted: { parameters: [{ name: 'boosted', type: 'boolean' }] },
    setPromoCode: { parameters: [{ name: 'promo code', type: 'string' }] },
    goUpgradeOffer: { parameters: [] },
    chooseNumbers: { parameters: [] },
  },
});

PLASMIC.registerGlobalContext(UserGlobalContext, {
  name: 'UserGlobalContext',
  props: {},
  providesData: true,
  globalActions: {
    submitAccountDetails: {
      parameters: [
        {
          name: 'file',
          type: 'string',
        },
        {
          name: 'leaderboardName',
          type: 'string',
        },
        {
          name: 'removedPicture',
          type: 'boolean',
        },
        {
          name: 'avatarIconLink',
          type: 'string',
        },
      ],
    },
    fetchWishListPrizes: { parameters: [] },
    toggleWishlistPrize: {
      parameters: [
        {
          name: 'prizeInstanceId',
          type: 'number',
        },
        {
          name: 'toggle',
          type: 'boolean',
        },
      ],
    },
    submitHelpQuestion: {
      parameters: [
        {
          name: 'question',
          type: 'string',
        },
      ],
    },
    logout: {
      parameters: [],
    },
  },
});

PLASMIC.registerGlobalContext(WinnerGlobalContext, {
  name: 'WinnerGlobalContext',
  props: {},
  providesData: true,
});

PLASMIC.registerGlobalContext(WinnerGlobalContext, {
  name: 'WinnerGlobalContext',
  props: {},
  providesData: true,
});

PLASMIC.registerComponent(TrendingThisMonthSection, {
  name: 'TrendingThisMonthSection',
  props: {
    verbose: 'boolean',
    children: 'slot',
  },
});

PLASMIC.registerGlobalContext(PrizeGlobalContext, {
  name: 'PrizeGlobalContext',
  props: {},
});

PLASMIC.registerComponent(EmptyText, {
  name: 'EmptyText',
  props: {
    text: 'string', 
    fontSize: 'string',
    strokeColor: 'string'
  },
});

PLASMIC.registerComponent(PlasmicEnterCheckout, {
  name: 'PlasmicEnterCheckout',
  props: {
    verbose: 'boolean',
    children: 'slot',
  },
});

PLASMIC.registerGlobalContext(PrizeGlobalContext, {
  name: 'PrizeGlobalContext',
  props: {},
  providesData: true,
});

PLASMIC.registerGlobalContext(ActivityFeedGlobalContext, {
  name: 'ActivityFeedGlobalContext',
  props: {},
  providesData: true,
});

PLASMIC.registerComponent(CardSlider, {
  name: 'CardSlider',
  props: {},
});

PLASMIC.registerComponent(CarouselSlider, {
  name: 'CarouselSlider',
  props: {
    variant: 'string',
  },
});

PLASMIC.registerComponent(EmailConfirmationBanner, {
  name: 'EmailConfirmationBanner',
  props: {
    emailAddress: 'string',
    bannerColor: 'string',
    textSize: 'string',
    imageSize: 'string',
    children: 'slot',
  },
});

PLASMIC.registerGlobalContext(ScrollGlobalContext, {
  name: 'Scroll',
  props: {},
  globalActions:{
    setShowDrawCounter: { parameters: [] },
  }
});

PLASMIC.registerGlobalContext(ConfirmationGlobalContext, {
  name: 'Confirmation',
  props: {},
});
